var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"home"},[_c('img',{staticClass:"main",attrs:{"src":require("@/assets/img-mohito.png")}}),_vm._m(0),_c('div',{staticClass:"boxes"},[_c('div',{staticClass:"box",on:{"click":function($event){return _vm.onClick('ai', 'AI 번호 생성')}}},[_c('img',{attrs:{"src":require("@/assets/ic-ai.svg"),"alt":"ai"}}),_vm._m(1)]),_c('div',{staticClass:"box",on:{"click":function($event){return _vm.onClick('dream', '꿈해몽 생성')}}},[_c('img',{attrs:{"src":require("@/assets/img-stella-2d.png"),"alt":"dream"}}),_vm._m(2)])]),_vm._m(3),_vm._m(4),_c('div',{staticClass:"share"},[_vm._m(5),_c('div',{staticClass:"share-channel"},[_c('div',{staticClass:"round kakao",on:{"click":_vm.shareKakao}},[_c('img',{attrs:{"src":require("@/assets/ic-system-kakao.svg"),"alt":"kakao"}})]),_c('div',{staticClass:"round sms",on:{"click":_vm.shareSms}},[_c('img',{attrs:{"src":require("@/assets/ic-system-sms.svg"),"alt":"sms"}})]),_c('div',{staticClass:"round url",on:{"click":_vm.shareUrl}},[_vm._v(" URL ")]),_c('div',{staticClass:"round more",on:{"click":_vm.shareNative}},[_c('div'),_c('div'),_c('div')])])]),_c('div',{staticClass:"floating"},[_c('div',{staticClass:"count__down"},[_vm._v(" "+_vm._s(_vm.countdown)+" ")])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"desc"},[_vm._v("ai 통계기반 알고리즘을 통해"),_c('br'),_vm._v("더 높은 로또 당첨 확률을 만나보세요!")])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"text"},[_vm._v("GTP가 분석해주는"),_c('br'),_c('span',[_vm._v("AI 번호 생성")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"text"},[_vm._v("조상신이 점지해주는"),_c('br'),_c('span',[_vm._v("꿈해몽 생성")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"algorithm"},[_c('div',{staticClass:"light"},[_c('img',{attrs:{"src":require("@/assets/ic-system-star.png")}})]),_c('div',{staticClass:"title"},[_vm._v("스테판이 생성해주는"),_c('br'),_vm._v("정교한 알고리즘")]),_c('div',{staticClass:"desc"},[_vm._v("ai 통계기반 알고리즘을 통해"),_c('br'),_vm._v("더 높은 로또 당첨 확률을 만나보세요!")]),_c('img',{attrs:{"src":require("@/assets/ic-system-intro-aicard.png")}})])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"dream"},[_c('img',{staticClass:"cloud",attrs:{"src":require("@/assets/ic-system-cloud.png")}}),_c('div',{staticClass:"title"},[_vm._v("꿈자리를 통한"),_c('br'),_vm._v("이상적인 번호 조합")]),_c('div',{staticClass:"desc"},[_vm._v("로또 1등 당첨자들이 가장 많이 꾼꿈을"),_c('br'),_vm._v("조합하여 이상적인 번호 생성을 도와드려요.")]),_c('img',{staticClass:"list",attrs:{"src":require("@/assets/ic-system-list.png")}})])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"title"},[_vm._v("친구, 지인과 함께"),_c('br'),_vm._v("로또 1등에 도전해보세요")])
}]

export { render, staticRenderFns }