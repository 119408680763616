<template>
  <div class="my">
    <div class="summary">
      <div class="img-bg">
        <img src="@/assets/ic-stefan-2d.svg" class="person" />
      </div>
      <div class="nickname">{{nickname}}</div>
      <div class="email">
        <div class="kakao">
          <img src="@/assets/ic-system-kakao.svg" />
        </div>
        <div v-if="user !== null">{{user.email}}</div>
        <div v-if="user === null" class="kakao-login" @click="onLogin">카카오 계정으로 로그인해주세요.</div>
      </div>
    </div>

    <iframe
      class="ad"
      src="https://ads-partners.coupang.com/widgets.html?id=800971&template=carousel&trackingCode=AF9553073&subId=&width=335&height=60&tsource="
      width="335"
      height="60"
      frameborder="0"
      scrolling="no"
      referrerpolicy="unsafe-url"
      browsingtopics
    ></iframe>

    <div class="menu">
      <div class="title">서비스</div>
        <div v-for="item in services" :key="item.title" class="service-item" @click="$router.push(item.link)">
          <span class="service-title">{{ item.title }}</span>
          <img src="@/assets/ic-system-arrow-right.svg" />
      </div>
    </div>

    <div class="menu">
      <div class="title">저장소</div>
      <div v-for="item in repositories" :key="item.title" class="service-item" @click="onNumber(item.title)">
        <span class="service-title">{{ item.title }}</span>
        <img src="@/assets/ic-system-arrow-right.svg" />
      </div>
    </div>

    <div class="menu">
      <div class="title">일반</div>
      <div v-for="(item, i) in general" :key="item.title" class="service-item" @click="onGeneral(i)">
        <span class="service-title">{{ item.title }}</span>
        <img src="@/assets/ic-system-arrow-right.svg" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator'
import Cookies from 'js-cookie'

@Component
export default class My extends Vue {
  nickname = '';
  user: any = {};

  services = [
    {
      title: 'Ai 로또생성',
      link: '/ai',
    },
    {
      title: '꿈해몽 생성',
      link: '/dream',
    },
  ];

  repositories = [
    {
      title: '나의 번호 관리'
    }
  ];

  general = [
    {
      title: '문의하기',
    },
    {
      title: '광고 문의',
    },
    {
      title: '개인 정보 보호 및 약관',
    },
  ];

  private adjectives: string[] = [
    '희망찬', '용감한', '즐거운', '활기찬', '씩씩한', '지혜로운', '사랑스러운', '기쁜', '자유로운', '귀여운',
  ];

  private animals: string[] = [
    '돼지', '호랑이', '사자', '고양이', '강아지', '곰', '여우', '토끼', '독수리', '판다',
  ];

  generateNickname(uid: string): string {
    const firstDigit = parseInt(uid.split('_')[1].charAt(0));
    const lastDigit = parseInt(uid.slice(-1), 10);
    const number = parseInt(uid.split('_')[1].substring(0, 4));

    const adjective = this.adjectives[firstDigit % this.adjectives.length - 1];
    const index = lastDigit % this.animals.length - 1;
    const adjustedIndex = index < 0 ? 0 : index;
    const animal = this.animals[adjustedIndex];

    return `${adjective}${animal}${number}`;
  }

  onGeneral(index: number) {
    if (index === 0 || index === 1) {
      this.openEmail(index === 0 ? '문의사항' : '광고문의');
    } else {
      this.$router.push('/terms');
    }
  }

  onNumber(title: string) {
    Cookies.set('menu', title)
    this.$router.push('/my/number')
  }

  openEmail(subjectTitle: string) {
    const email = 'mohito.project@gmail.com'; 
    const subject = encodeURIComponent(subjectTitle);
    const body = encodeURIComponent('여기에 내용을 입력하세요.');

    const mailtoLink = `mailto:${email}?subject=${subject}&body=${body}`;
    window.location.href = mailtoLink;
  }

  onLogin() {
    this.$router.replace('/login');
  }

  created() {
    const userData = Cookies.get('user');

    if (userData) {
      try {
        this.user = JSON.parse(userData);
        this.nickname = this.generateNickname(this.user.uid);
      } catch (error) {
        console.error('Failed to parse user data:', error);
        this.user = null;
      }
    } else {
      this.user = null;
      this.nickname = 'Guest';
    }
  }
}
</script>

<style scoped>
.my {
  margin-top: 24px;
  margin-bottom: calc(40px + 64px + env(safe-area-inset-bottom));
  padding: 0 20px;
}

.summary {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.summary > .img-bg {
  width: 64px;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #4262FF;
  border-radius: 50%;
  margin-bottom: 8px;
}

.summary > .img-bg > img {
  width: 40px;
  height: 40px;
  text-align: center;
}

.summary > .nickname {
  margin-bottom: 4px;
  font-size: 22px;
  font-weight: 600;
  line-height: 33px;
  letter-spacing: -0.5px;
  text-align: center;
  color:#ECEEF0;
}

.summary > .email {
  display: flex;
  gap: 6px;
  align-items: center;
  margin-bottom: 24px;
  font-size: 15px;
  font-weight: 400;
  line-height: 18px;
  color: #9C9EA0;
}

.kakao-login {
  cursor: pointer;
}

.summary > .email > .kakao {
  background-color: #ECEEF0;
  width: 18px;
  height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 75px;
}

.summary > .email > .kakao > img {
  width: 13.5px;
  height: 13.5px;
}

.ad {
  margin-bottom: 24px;
  background-color: #F3F3F3;
  width: 100%;
  height: 62px;
}

.menu {
  margin-bottom: 28px;
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
  color: #9C9EA0;
}

.menu > .title {
  margin-bottom: 1px;
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
  text-align: left;
  color: #9C9EA0;
}

.service-item {
  display: flex;
  align-items: center;
  padding: 7px 0;
  cursor: pointer;
}

.service-item:last-child {
  border-bottom: none;
}

.service-icon {
  width: 24px;
  height: 24px;
  margin-right: 12px;
}

.service-title {
  flex-grow: 1;
  font-size: 18px;
  font-weight: 600;
  line-height: 26px;
  color: #ECEEF0;
}

.arrow-icon {
  width: 24px;
  height: 24px;
}
</style>